import { EventBus } from '../lib/EventBus';
import * as dc from 'dc';

function getActivePersons(crossfilter) {
    // determine the selected persons and update the table
    return new Set(crossfilter.allFiltered()
        .map(evt => evt.protagonistLabel));
    //console.log("currentActivePersons (from events): ", currentActivePersons);
  }

function filterDataBasedOnPersons(persons, externalPersonsDimension, crossfilter) {
    //console.log("filterDataBasedOnPersons: using persons as filter criterion: ", persons.length, persons);
    //console.log("from initial authors, persons filter contains: ", persons.filter(p => initialAuthors.has(p)));
    if (externalPersonsDimension) {
      //console.log("disposing current external persons dimensions: ", this.externalPersonsDimension);
      externalPersonsDimension.dispose();
    }
    // it seems that on merely creating the dimension, the views will be updated,
    // i.e. dc.redrawAll() is not necessary!
    console.log("applying new persons as filter");
    externalPersonsDimension = crossfilter.dimension((d) => d.protagonistLabel);

    return externalPersonsDimension.filterFunction((person) => {
      return persons.includes(person);
    });
  }

// this function is called whenever an external filter is applied or removed - here, we check which external filters are active
// and, based on this, calculate the filtered persons, for which a new dimension will be created
function applyExternalFilters(localFilterState, externalFilters, initialAuthors, externalPersonsDimension, currentActivePersons, crossfilter, charts, componentid) {
    console.log("applyExternalFilters(): localFilterState: ", localFilterState);
    console.log("applyExternalFilters(): this: ", this);
    // we need to collect the fltered filtered persons from the active external filters - currently
    // use only the last element from external filters
    localFilterState.activeExternalFilters = new Set(externalFilters.filter(filter => filter.active).map(filter => filter.componentid));
    const personsFromExternalFilters = Array.from(externalFilters.filter(filter => filter.active).reduce((currentIntersection,curr) => {
      const currentPersonsForIntersection = curr.persons;
      console.log("currentPersonsForIntersection: ", currentPersonsForIntersection);
      const newIntersection = new Set([...currentIntersection].filter(person => currentPersonsForIntersection.includes(person)));
      return newIntersection;
    },new Set(initialAuthors)));
    // this is OA's filter method without any changes
    //this.filterDataBasedOnPersons(personsFromExternalFilters);
    //this.currentActivePersons = effunctions.filterDataBasedOnPersons(personsFromExternalFilters, this.externalPersonsDimension, this.cf);
    externalPersonsDimension = filterDataBasedOnPersons(personsFromExternalFilters, externalPersonsDimension, crossfilter);
    currentActivePersons = getActivePersons(crossfilter);
    // update table data
    //this.tableData= JSON.parse(JSON.stringify(this.cf.allFiltered()));
    //this.$refs.easyDataTable.updatePage(1);

    // the active persons do not need to be recalculated here as this is already done in filterDataBasedOnPersons()
    EventBus.emit('filteredPersons', {
      componentid: componentid,
      persons: Array.from(currentActivePersons),
      filterState: localFilterState
    });

    // now rerender (also taken from OA's handleUserActionToFilter() method)
    dc.chartRegistry.list().forEach(chart => {
      if (charts.indexOf(chart) != -1) {
        console.log("Publiations: re-render my own chart: ", chart);
        chart.render();
      } else {
        console.log("Publiations: skip re-rendering chart: ", chart);
      }
    });

    return [externalPersonsDimension, currentActivePersons]
  }

function collectMetadataFromExternalFilters(filter){
    const filterStateToFilterAttribute = {
      'publicationsByPublishers': 'Number of Publications by Publisher',
      'authorsForPublishers': '15 Topmost Publishers for the Number of Authors',
      'rolesByPublications': 'Number of Authors by Role',
      'publicationForAuthorsTopN': 'Number of Publications by Author',
      'timelineByNumberOfAuthors': 'Timeline with Number of Authors',
      'activeExternalFilters': 'Active external filters',
      "personsByGender": 'Gender',
      'eventByPersons': 'Number of Persons by Event Type',
      'eventByEvents': 'Number of Events by Event Type',
      'eventsForPersons': 'Number of Events by Persons',
      'professionsEvaluation': 'Professions charts',
      'institutionsEvaluation': 'Institutions charts',
      'referencesEvaluation': 'Repository charts',
      'timelineByEventsWithPersonCount': 'Timeline with Number of Events',
      'timelineByNumberOfEvents': 'Timeline with Number of Persons',
      }

    const filterDetailsInfo = document.createElement("div");
    filterDetailsInfo.classList.add('div-ext-filters');
    console.log("collectMetadataFromExternalFilters() here: ", filter);
    
    filterDetailsInfo.textContent = Object.keys(filter.filterState)
              .map(filterName => {
                const currentFilterValue = filter.filterState[filterName];
                // this is for bar and pie charts
                if (currentFilterValue instanceof Set) {
                  if (currentFilterValue.size > 0) {
                    return filterStateToFilterAttribute[filterName] + ": " + Array.from(currentFilterValue);
                  }
                  else {
                    return null;
                  }
                }
                // this is for timeline
                else if (currentFilterValue.from && currentFilterValue.to) {
                  return filterStateToFilterAttribute[filterName] + ": " + currentFilterValue.from.getYear() + "-" + currentFilterValue.to.getYear();
                }
                else {
                  return null;
                }
              })
              .filter(val => val != null)
              .join("; ");
    return filterDetailsInfo;
}

function filterStateInfo(filter){
    const filterStateInfo = document.createElement("div");
    filterStateInfo.classList.add('div-active-from-ext-filter');
    filterStateInfo.textContent = "Number of active persons: " + filter.persons.length; // "active: " + filter.active + "; updated: " + filter.updated + 
    return filterStateInfo    
}

export {
    getActivePersons,
    filterDataBasedOnPersons,
    applyExternalFilters, 
    collectMetadataFromExternalFilters,
    filterStateInfo
}
