<template>
  <div :class="{ 'table-hidden' : !isTableVisible}">
    <h1>Publications View</h1>

    <div class="filter-and-charts-container">
    <div>
        <h2>Filters</h2>
      
      <div class="filter-container">
        <div>
          <h3 class="filter-head">External</h3>
          <div ref="externalFiltersRoot" />
        </div>
        <div style="width:350px;">
          <div style="display:inline-flex;">
            <h3 class="filter-head">Local</h3>
            <div v-if="Object.keys(localFilterState).length > 3" class="remove-filter-btn" @click="removeFilters">Remove all</div>
          </div>
          <div ref="localFiltersRoot" />
        </div>
      </div>
    </div>

    <div ref="chartsRoot" class="chart-container">
      <div style="display:flex">
        <h2>Charts</h2>
        <div class="active-persons">Currently active persons: {{ currentActivePersons.size }}</div>
      </div>

      <div>
        <button class="btn-show-chart" v-for="chartId in chartOrder" v-bind:key="chartId" v-html="chartTitles[chartId]"
                @click="showOrRemoveChartInView(chartId)" :class="{ active: isOpen(chartId) }"></button>
        <!-- Button to toggle table visibility -->
        <button @click="isTableVisible = !isTableVisible" class="btn-show-chart">
          {{ isTableVisible ? 'Hide Table' : 'Show Table' }}
        </button>
      </div>
      <br />

      <div class="flex-container">
        <div v-for="chartId in Object.keys(chartIsOpen).filter(key => chartIsOpen[key])" v-bind:key="chartId" class="chart-card">
          <details @click="toggleChart(chartId)"
                   :open="isOpen(chartId)">
            <summary v-html="chartTitles[chartId]"></summary>
            <div :id="chartId" />
          </details>
        </div>
      </div>
    </div>
    </div>

    <!-- Table Section, visible based on isTableVisible -->
    <div v-show="isTableVisible" ref="tableRoot" class="table-container">
      <div>
        <span>Search value: </span>
        <input type="text" v-model="searchTerm">
      </div>

      <!-- EasyDataTable -->
      <EasyDataTable ref="easyDataTable" buttons-pagination alternating multi-sort :headers="tableHeaders"
                     :items="tableData" :items-per-page="itemsPerPage" :search-value="searchTerm" :sort-by="sortBy"
                     :sort-type="sortType">
      </EasyDataTable>
    </div>
  </div>
</template>

<script>
import * as dc from 'dc';
// eslint-disable-next-line no-unused-vars
import * as d3 from 'd3';
// eslint-disable-next-line no-unused-vars
import * as dcleaflet from 'dc.leaflet'
import crossfilter from 'crossfilter2';
import 'leaflet/dist/leaflet.css'; // Import Leaflet CSS
// eslint-disable-next-line no-unused-vars
import L from 'leaflet';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet.markercluster';
import './css/dc.css';
import './css/main.css';
//import {lineChart} from "dc";
//import * as functions from "@/lib/SharedFunctions";
//import * as constants from "@/lib/SharedConstants";
import * as effunctions from "@/lib/SharedExternalFiltersFunctions"
import * as charts from "@/lib/SharedChartCreators";
import {PUBLICDATA} from "@/lib/SharedConstants";
import { EventBus } from '../lib/EventBus';
import EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';


let instancecount = 0;

/* eslint-disable no-unused-vars */
export default {
  name: 'PublicationsView',
  components: {
    EasyDataTable
  },
  data() {
    return {
      cf: null,
      mycharts: [],
      markers: null,
      allPublications: [],
      tableData: [],
      searchResults: null,
      isTableVisible: true,
      pendingFilteredPersonsFromEvent: null,
      externalFilterApplied: false,
      savedFilterPersons: null,
      searchTerm: '',      // Speichert den Suchbegriff
      itemsPerPage: 25,
      tableHeaders: [
        { text: 'Begin', value: 'begin', sortable: true },
        { text: 'ID', value: 'id', sortable: true },
        { text: 'Author', value: 'protagonistLabel', sortable: true },
        { text: 'Publisher', value: 'publisher', sortable: true },
        { text: 'Role', value: 'role', sortable: true },
        { text: 'Title', value: 'title', sortable: true },
        { text: 'Type', value: 'type', sortable: true }
      ],
      sortBy: [],
      sortType: ["asc", "desc"],
      chartOrder: [
      'chart-container-publisher',
      'barchart-authors-publishers',
      'chart-container-pubrole',
      'barchat-publications-authors',
      'timeline-publications'
      ],
      chartTitles: {
      'chart-container-publisher': 'Number of Publications by Publisher',
      'barchart-authors-publishers': '15 Topmost Publishers for the Number of Authors',
      'chart-container-pubrole': 'Number of Authors by Role',
      'barchat-publications-authors': 'Number of Publications by Author',
      'timeline-publications': 'Timeline with Number of Authors',
      },
      chartIsOpen: {
        'chart-container-publisher': false,
        'barchart-authors-publishers': true,
        'chart-container-pubrole': true,
        'barchat-publications-authors': true,
        'timeline-publications': false,
      },
      chartsVisibility: {
        'chart-container-publisher': {
          // the creator functions need to return the chart object in order for toggleChart() to work
          creatorfunc: () => this.createPieChartNumberOfPublicationsByPublishers(),
        },
        'barchart-authors-publishers': {
          creatorfunc: () => this.createBarChartNumberOfAuthorsForPublishers(),
        },
        'chart-container-pubrole': {
          creatorfunc: () => this.createBarChartForRoles(),
        },
        'barchat-publications-authors': {
          creatorfunc: () => this.createBarChartNumberOfPublicationsForAuthorsTopN(),
        },
        'timeline-publications': {
          creatorfunc: () => this.createTimelineChartNumberOfAuthors(),
        }, 
      },
    };
  },
  methods: {
    toggleChart(chartID) {
      // check whether the chart has already been created
      const chartSelector = "#" + chartID + " svg";
      const chartElement = this.chartsRoot.querySelector(chartSelector);
      // console.log("toggleChart(): got element for selector: ", chartSelector, chartElement);
      if (!chartElement) {
        // console.log("toggleChart(): will create chart for: ", chartID, this.chartsVisibility[chartID]);
        // 1. call the creator function for which the chart has been registered
        let chartOrCharts = this.chartsVisibility[chartID].creatorfunc();
        if (!Array.isArray(chartOrCharts)) {
          chartOrCharts = [chartOrCharts];
        }
        chartOrCharts.forEach(chart => {
          // 2. set the filtered listener on the chart
          chart.on("filtered", () => this.onChartFiltered());
          // 3. if the creator function cannot call render() at the very end, we might need to call it here
          chart.render();
        })
      }
      // this.chartsVisibility[chartID].visible = !this.chartsVisibility[chartID].visible;
      localStorage.setItem(this.componentid + ".chartsVisibility", JSON.stringify(this.chartsVisibility));
    },
    isOpen(chartID) {
      if (this.chartIsOpen[chartID] == true) {
        return chartID
      }
    },
    showOrRemoveChartInView(chartID){
      if (this.chartIsOpen[chartID] == true) {
        console.log('try to remove chart');
        document.getElementById(chartID).parentNode.parentNode.remove();
        this.chartIsOpen[chartID] = false;
      }
      else {
      this.chartIsOpen[chartID] = true
      setTimeout(() => {
        // Code to be executed after the delay
        console.log('Action after 2 seconds');
        this.toggleChart(chartID)
      }, 10);
    }
    },
    removeFilters() {
      this.localFilterState = {};
      this.renderLocalFiltersView();
      this.externalFilters.forEach(filter => {filter.active = false});
      this.renderExternalFiltersView();
      dc.filterAll(this.componentid);
    },
    /* reducer functions for calculating groups based on the number of unique events and unique persons
    * ... and it turns out that this is slightly more complicated as expected as the performance advantage
    * of dc is, at least partially, achieved by applying the reduceRemove function when the overall set is filtered.
    * However, in cases where for some value set, e.g. authors, the values of some filter dimension (e.g. publisher) can be
    * multiply assigned, reduceRemove results in wrong results if that dimension is not foreseen in the data structure
    * underlying some chart. We observed this issue when displaying roles by number of authors being filtered for publisher.
    * Therefore, the removeAdd function now creates a result that keeps all authors for some publisher. Need to see whether,
    * reversely, we need a similar solution when using roles as filter and displaying the number of publications by publisher
    * where some author appears in that role.
    * */
    createKeyForReducer(dataitem) {
      return "_" + dataitem.publisher
        + "_" + dataitem.role
        + "_" + dataitem.begin
        + "_" + dataitem.protagonistLabel
        + "_";
    },
    reduceCountsAdd(acc, curr) {
      // console.log("reduceCountsAdd(): ", acc, curr);
      // we create keys that concatenate, for each element in the data set, the values
      // of the filter dimensions, we are interested in and use these concatenated strings
      // as keys in the object to be created and manipulated by the reduce functions
      const key = this.createKeyForReducer(curr);

      if (!acc[key]) {
        acc[key] = {
          uniquePublications: new Set(),
          uniqueAuthors: new Set()
        }
      }
      acc[key].uniquePublications.add(curr.id);
      acc[key].uniqueAuthors.add(curr.protagonistLabel);

      return acc;
    },

    reduceCountsRemove(acc, curr) {
      const key = this.createKeyForReducer(curr);

      // this check has been introduced on using the fake dimension for the author barchart
      // where not all data is present anymore - however, this results in data being corrupted
      // as figures will only be correct if add and remove are applied to the complete data set
      // if (acc[key]) {
      acc[key].uniquePublications.delete(curr.id);
      acc[key].uniqueAuthors.delete(curr.protagonistLabel);
      // }
      // else {
      //   // console.log("reduceCountsRemove(): no entry found for ", key, curr, acc);
      // }

      return acc;
    },

    reduceCountsInitial() {
      // console.log("reduceEventIdsInitial()")
      return {}
    },

    createBarChartForRoles() {
      // depending on the type of chart, we create a new attribute in localFilterState and instantiate
      // it accordingly (for barCharts and pieCharts we will use a set that contains the values that are
      // currently filtered-in)
      this.localFilterState.rolesByPublications = new Set()
      const chart = charts.createBarChart(this.componentid, this.cf,
        //this.myroot.querySelector("#chart-container-pubrole"),
        this.chartsRoot.querySelector("#chart-container-pubrole"),
        "role",
        this.reducersForPublications,
        this.getAuthorsCountFromReducedValue,
        {
          xLabel: "Role",
          yLabel: "Number of Persons"
        },
        { top: 30, right: 0, bottom: 100, left: 50 },
        { filterState: this.localFilterState.rolesByPublications }
      );
      this.mycharts.push(chart);
      return chart;
    },

    getPublicationsCountFromReducedValue(d) {
      // console.log("getPublicationsCountForReducedValue(): ", d);
      return Object.values(d.value)
        .map(val => val.uniquePublications)
        .reduce((acc, curr) => {
          curr.forEach(el => acc.add(el));
          return acc;
        }, new Set()).size;
    },

    getAuthorsCountFromReducedValue(d) {
      return Object.values(d.value)
        .map(val => val.uniqueAuthors)
        .reduce((acc, curr) => {
          curr.forEach(el => acc.add(el));
          return acc;
        }, new Set()).size;
    },

    /* pie chart example from the events chart */
    createPieChartNumberOfPublicationsByPublishers() {
      console.log("createPieChartNumberOfPublicationsByPublishers(): this.chartsRoot is: ", this.chartsRoot);
      const publisherDimension = this.cf.dimension((d) => d.publisher);
      const publicationsByPublishersCount = publisherDimension.group().reduce(this.reduceCountsAdd, this.reduceCountsRemove, this.reduceCountsInitial);

      this.localFilterState.publicationsByPublishers = new Set()
      const chart = charts.createPieChart(this.componentid, 
        this.chartsRoot.querySelector("#chart-container-publisher"),
        publisherDimension,
        publicationsByPublishersCount,
        this.getPublicationsCountFromReducedValue,
        { cap: 25, filterState: this.localFilterState.publicationsByPublishers }
      );
      this.mycharts.push(chart);
      return chart;
    },


    createTimelineChartNumberOfAuthors() {
      const publicationsWithDate = this.data.filter(pub => pub.begin).filter(pub => !isNaN(pub.begin));

      // the date dimension: we start using single years rather than year intervals
      // NOTE: the dateDimension must return either Date objects or timestamps in milliseconds. If we
      // retur the value of pub.begin (which is years), it will be interpreted as milliseconds, and we end up
      // with all values be placed around 1970
      const dateDimension = this.cf.dimension(pub => pub.begin && pub.begin != "null"
        ? new Date(pub.begin, 1, 1).getTime()
        : new Date(2000, 1, 1).getTime());

      // the grouping for the date dimension: 
      const yearGroup = dateDimension.group().reduce(this.reduceCountsAdd, this.reduceCountsRemove, this.reduceCountsInitial);

      this.localFilterState.timelineByNumberOfAuthors = {
        from: null,
        to: null
      };
      const chart = charts.createTimelineChart(
        this.componentid, 
        this.chartsRoot.querySelector('#timeline-publications'),
        dateDimension,
        yearGroup,
        this.getAuthorsCountFromReducedValue,
        publicationsWithDate,
        { filterState: this.localFilterState.timelineByNumberOfAuthors }
      );
      this.mycharts.push(chart);
      return chart;
    },

    logComparedata() {
      const comparedataAllKiBuV = this.data.filter(pub => pub.publisher === "Kinderbuchverl.")
        .reduce((acc, curr) => {
          acc.uniqueAuthors.add(curr.protagonistLabel);
          acc.uniquePublications.add(curr.id);
          if (!acc["authors_as_" + curr.role]) {
            acc["authors_as_" + curr.role] = new Set();
          }
          acc["authors_as_" + curr.role].add(curr.protagonistLabel);
          if (!acc["publications_with_" + curr.role]) {
            acc["publications_with_" + curr.role] = new Set();
          }
          acc["publications_with_" + curr.role].add(curr.id);
          if (!acc["publication_year_" + curr.begin]) {
            acc["publication_year_" + curr.begin] = new Set();
          }
          acc["publication_year_" + curr.begin].add(curr.id);
          if (!acc["role_" + curr.role + "_in_year_" + curr.begin]) {
            acc["role_" + curr.role + "_in_year_" + curr.begin] = new Set();
          }
          acc["role_" + curr.role + "_in_year_" + curr.begin].add(curr.protagonistLabel);
          if (!acc["publications_with_role_" + curr.role + "_in_year_" + curr.begin]) {
            acc["publications_with_role_" + curr.role + "_in_year_" + curr.begin] = new Set();
          }
          acc["publications_with_role_" + curr.role + "_in_year_" + curr.begin].add(curr.id);
          return acc;
        }, { uniqueAuthors: new Set(), uniquePublications: new Set() });
      // console.log("allKiBuV: ", comparedataAllKiBuV);

      const comparedataAllButKiBuV = this.data.filter(pub => pub.publisher !== "Kinderbuchverl.")
        .reduce((acc, curr) => {
          acc.uniqueAuthors.add(curr.protagonistLabel);
          acc.uniquePublications.add(curr.id);
          if (!acc["authors_as_" + curr.role]) {
            acc["authors_as_" + curr.role] = new Set();
          }
          acc["authors_as_" + curr.role].add(curr.protagonistLabel);
          if (!acc["publications_with_" + curr.role]) {
            acc["publications_with_" + curr.role] = new Set();
          }
          acc["publications_with_" + curr.role].add(curr.id);
          if (!acc["publication_year_" + curr.begin]) {
            acc["publication_year_" + curr.begin] = new Set();
          }
          acc["publication_year_" + curr.begin].add(curr.id);
          if (!acc["role_" + curr.role + "_in_year_" + curr.begin]) {
            acc["role_" + curr.role + "_in_year_" + curr.begin] = new Set();
          }
          acc["role_" + curr.role + "_in_year_" + curr.begin].add(curr.id);
          return acc;
        }, { uniqueAuthors: new Set(), uniquePublications: new Set() });
      // console.log("allButKiBuV: ", comparedataAllButKiBuV);

      const compareDataOnlyKiBuV = { ...comparedataAllKiBuV };
      Object.keys(compareDataOnlyKiBuV).forEach(key => {
        const currentValueOnlyKiBuV = new Set(compareDataOnlyKiBuV[key]);
        if (comparedataAllButKiBuV[key] instanceof Set) {
          comparedataAllButKiBuV[key].forEach(val => currentValueOnlyKiBuV.delete(val));
          compareDataOnlyKiBuV[key] = currentValueOnlyKiBuV;
        }
      });
      // console.log("onlyKiBuV: ", compareDataOnlyKiBuV);
    },

    createBarChartNumberOfAuthorsForPublishers() {
      this.localFilterState.authorsForPublishers = new Set()
      const chart = charts.createBarChart(this.componentid, this.cf,
        //this.myroot.querySelector("#barchart-authors-publishers"),
        this.chartsRoot.querySelector("#barchart-authors-publishers"),
        "publisher",
        this.reducersForPublications,
        this.getAuthorsCountFromReducedValue,
        {
          xLabel: "Publishers",
          yLabel: "Number of Authors"
        },
        { top: 30, right: 0, bottom: 100, left: 50 },
        { topN: 15,  filterState: this.localFilterState.authorsForPublishers }
      );
      this.mycharts.push(chart);
      return chart;
    },

    // taken from: https://stackoverflow.com/questions/30977987/plotting-top-values-of-a-group-on-dc-js-bar-chart
    // this constrains a group to the n topmost values - note, however, that here the reducer-objects do not seem to
    // be used, i.e. the ranking is based on the overall counts (need to see whether this is the case also for the
    // other charts)
    // see https://github.com/dc-js/dc.js/wiki/FAQ#filter-the-data-before-its-charted for an
    // "offical" recommendation of such "fake groups" that can be used if data needs to be preprocessed
    // see also here: https://groups.google.com/g/dc-js-user-group/c/zwS6bisSofE?pli=1
    // before being displayer
    // see also: https://groups.google.com/g/dc-js-user-group/c/zwS6bisSofE
    //
    // none of the proposed solutions works, included passing the top-N authors as domain - here
    // all other authors will be placed at x=0 and can be filtered out in CSS, but the domain,
    // even if updated regularly will be overridden by the elasticX feature, which will
    // automatically result in using the whole data set as basis for drawing the graph.
    // Therefore, we draw the graph and after drawing rearrange the bars, which is, unfortunately,
    // rather heavy as it included dom manipulations
    //
    // both fake groups and fake dimensions result in trouble when it comes to
    // applying the filter reducers
    createBarChartNumberOfPublicationsForAuthorsTopN() {
      this.localFilterState.publicationForAuthorsTopN = new Set()
      const chart = charts.createBarChart(this.componentid, this.cf,
        //this.myroot.querySelector("#barchat-publications-authors"),
        this.chartsRoot.querySelector("#barchat-publications-authors"),
        "protagonistLabel",
        this.reducersForPublications,
        this.getPublicationsCountFromReducedValue,
        {
          xLabel: "Publishers",
          yLabel: "Number of Authors"
        },
        { top: 30, right: 0, bottom: 100, left: 50 },
        { topN: 15, filterState: this.localFilterState.publicationForAuthorsTopN }
      );
      this.mycharts.push(chart);
      return chart;
    },
    removeLocalFilterCard(element, chart) {
    const filterAttributeToFilterState = {
        'Number of Publications by Publisher': 'publicationsByPublishers' ,
        '15 Topmost Publishers for the Number of Authors': 'authorsForPublishers' ,
        'Number of Authors by Role': 'rolesByPublications',
        'Number of Publications by Author': 'publicationForAuthorsTopN',
        'Timeline with Number of Authors': 'timelineByNumberOfAuthors',
        'Active external filters': 'activeExternalFilters',
      }
    const filterStateToChartRegisterAnchor = {
        'publicationsByPublishers': 'chart-container-publisher' ,
        'authorsForPublishers': 'barchart-authors-publishers' ,
        'rolesByPublications': 'chart-container-pubrole',
        'publicationForAuthorsTopN': 'barchat-publications-authors',
        'timelineByNumberOfAuthors': 'timeline-publications',
      }


    // Remove the parent element (which is the filter card)
    element.parentNode.removeChild(element);
    const filterStateChart = filterAttributeToFilterState[chart.split(':')[0]]; 
    //delete this.localFilterState[filterStateChart];
    
    // Update the chart: remove selected filter
    dc.chartRegistry.list(this.componentid).forEach(chartInRegistry => {
      if (chartInRegistry._anchor.id == filterStateToChartRegisterAnchor[filterStateChart] ) {
        chartInRegistry.filter(null).render();
        //this.chartIsOpen[chartInRegistry._anchor.id] = false;
      }});

    // Update apply/remove button of the external filter card
    if (chart.includes('external filter')) {
      const componentIdExternalFilter =  chart.split(':')[1];
      this.externalFilters.forEach(filter => {
        if (filter.componentid == componentIdExternalFilter.trim()) {
          filter.active = false}
      });
    this.renderExternalFiltersView();
    }},
    renderLocalFiltersView() {
      console.log("renderLocalFiltersView() publicationsView: ", this.localFiltersRoot, this.localFilterState);
      this.localFiltersRoot.innerHTML = "";
      const filterDetailsInfo = document.createElement("div");
      
      effunctions.collectMetadataFromExternalFilters({filterState: this.localFilterState}).textContent.split(';').forEach(filter => {
        const divElement = Object.assign(document.createElement("div"), { id: filter});
        const pElement = Object.assign(document.createElement("p"), { textContent: filter , className: "local-filter-card"});
        const removeElement = Object.assign(document.createElement("span"), { textContent: 'x', className: "remove-each-local-filter-btn"});

        if (filter.trim() !== "") { // Check if the filter is not empty after trimming whitespaces
          removeElement.addEventListener("click", () => {
            this.removeLocalFilterCard(divElement, filter);
          });
          divElement.appendChild(removeElement);
          divElement.appendChild(pElement);
          filterDetailsInfo.appendChild(divElement);
        }
      })

      this.localFiltersRoot.appendChild(filterDetailsInfo);
    },
    renderExternalFiltersView() {
      console.log("renderExternalFiltersView()", this.externalFilters);
      // reset
      this.externalFiltersRoot.innerHTML = "";
      // iterate over the filters
      this.externalFilters.forEach(filter => {
        const filterArea = document.createElement("fieldset");
        filterArea.appendChild(Object.assign(document.createElement("legend"), { textContent: filter.componentid + (filter.removed ? " [removed]" : "") }));
        // for transparency, we display the state
        filterArea.appendChild(effunctions.filterStateInfo(filter));

        // collect metadata from the external filters
        if (filter.filterState) {
          effunctions.collectMetadataFromExternalFilters(filter).textContent.split('; ').forEach(f => {
            filterArea.appendChild(Object.assign(document.createElement("div"), { textContent: f, className: 'div-ext-filters' }));
          })
        }

        // here's the main button - for applying/removing a filter
        const filterMainAction = filterArea.appendChild(Object.assign(document.createElement("button"), { textContent: filter.active ? "Remove" : "Apply", className:"btn-apply" }))

        filterMainAction.onclick = () => {
          filter.active = !filter.active;
          const applyExternalFiltersArray = effunctions.applyExternalFilters(this.localFilterState, this.externalFilters, this.initialAuthors, this.externalPersonsDimension, this.currentActivePersons, this.cf, this.mycharts, this.componentid);
          this.externalPersonsDimension = applyExternalFiltersArray[0];
          this.currentActivePersons = applyExternalFiltersArray[1];
          // do what we need to do given the new state of the filter
          // (...)
          // refresh the filters view
          // we remove the updated attribute
          filter.updated = false;
          this.renderExternalFiltersView();
        }
        if (filter.active && filter.updated) {
          // an additional action if a filter is active and has been updated
          const filterAdditionalAction = filterArea.appendChild(Object.assign(document.createElement("button"), { textContent: "Re-apply", className: "btn-apply" }));
          filterAdditionalAction.onclick = () => {
            const applyExternalFiltersArray = effunctions.applyExternalFilters(this.localFilterState, this.externalFilters, this.initialAuthors, this.externalPersonsDimension, this.currentActivePersons, this.cf, this.mycharts, this.componentid);
            this.externalPersonsDimension = applyExternalFiltersArray[0];
            this.currentActivePersons = applyExternalFiltersArray[1];

            // do what we need to do, i.e. apply the new filter criteria
            // ... and refresh state+view
            filter.updated = false;
            this.renderExternalFiltersView();
          }
        }
        // DO NOT FORGET TO ADD THE FILTER AREA TO THE ROOT ELEMENT...
        this.externalFiltersRoot.appendChild(filterArea);
        this.renderLocalFiltersView();
      });

      //Update table
      this.tableData = JSON.parse(JSON.stringify(this.cf.allFiltered()));
      this.$refs.easyDataTable.updatePage(1);
    },
    
    onChartFiltered() {
      this.currentActivePersons = effunctions.getActivePersons(this.cf);
      // Update data for the tableData
      this.tableData = JSON.parse(JSON.stringify(this.cf.allFiltered()));

      // Update the local filter state
      this.renderLocalFiltersView();

      console.log("Publications: emit filteredPersons", this.localFilterState);
      // here, we need to somehow tell the crosssfilter object for events that these persons shall be filtered
      EventBus.emit('filteredPersons', {
        componentid: this.componentid,
        persons: Array.from(this.currentActivePersons),
        filterState: this.localFilterState
      });
      this.$refs.easyDataTable.updatePage(1)
    }
  },

  created() {
    instancecount++;
    // console.log("Publications.created(): ", this.$refs.chartsRoot, this);
    this.componentid = "publications-" + instancecount;
    this.currentActivePersons = new Set();
    this.externalFilters = [];
    this.localFilterState = {};

    EventBus.on("externalFilterTabRemoved", (eventpayload) => {
      if (eventpayload.componentid == this.componentid) {
        console.log("ignore event emitted by ourselves", eventpayload.componentid, this.componentid);
        return;
      }
      const existingFilterForComponentid = this.externalFilters.filter(fil => fil.componentid === eventpayload.componentid)[0];
      if (existingFilterForComponentid) {
        existingFilterForComponentid.removed = true;
        this.renderExternalFiltersView();
      }
    });
    EventBus.on('filteredPersons', (eventpayload) => {
      if (eventpayload.componentid == this.componentid) {
        console.log("ignore event emitted by ourselves", eventpayload.componentid, this.componentid);
        return;
      }
      // Speichern der empfangenen Daten in der pendingFilteredPersonsFromEvent Variable
      this.pendingFilteredPersonsFromEvent = eventpayload.persons;
      //console.log("Publications: empfangene gefilterte Personen: ", eventpayload.persons);
      // use external filters. First check whether we already have a filter
      // for the component that sends us the data
      const existingFilterForComponentid = this.externalFilters.filter(fil => fil.componentid === eventpayload.componentid)[0];
      if (existingFilterForComponentid) {
        //console.log("externalFilters: updating existing external filter for componentid ", eventpayload.componentid, ": ", eventpayload);
        existingFilterForComponentid.persons = eventpayload.persons;
        // updated and active can be used to handle issues #35 and #39, respectively
        existingFilterForComponentid.updated = true;
        // active=true means that the filter is currently selected and has been applied
        // for an existing filter, we will not modify the active state as it might be still selected
        // existingFilterForComponentid.active = false;
      }
      else {
        //console.log("externalFilters: adding new external filter for componentid ", eventpayload.componentid, ": ", eventpayload);
        this.externalFilters.push({ ...eventpayload });
        this.externalFilters[this.externalFilters.length - 1].active = false;
        // a new filter will not receive the updated attribute
      }
      //console.log("externalFilters are now: ", this.externalFilters);
      // we update the view HERE, i.e. we do not need to bind update to the lifecycle events like activated()
      this.renderExternalFiltersView();
      //Update table
      this.tableData = JSON.parse(JSON.stringify(this.cf.allFiltered()));
      this.$refs.easyDataTable.updatePage(1);
    });
  },

  beforeUnmount() {
    console.log("Publications.beforeUnmount()");
  },

  unmounted() {
    console.log("Publications.unmounted()");
  },

  deactivated() {
    this.hasBeenDeactivated = true;
  },

  activated() {
    console.log("Publications.activated(): ", this.chartsRoot, this.externalFiltersRoot);
  },

  mounted() {
    // first of all, try to get a token from the url
    const params = new URLSearchParams(window.location.search)
    const token = params.get("token");

    this.tableRoot = this.$refs.tableRoot;
    this.chartsRoot = this.$refs.chartsRoot;
    this.externalFiltersRoot = this.$refs.externalFiltersRoot;
    this.localFiltersRoot = this.$refs.localFiltersRoot;
    console.log("Publications.mounted(): ", this, this.$refs.chartsRoot);
    console.log("Publications.mounted(): root elements have been set: ", this.tableRoot, this.chartsRoot);

    // Parse CSV data
    // console.log("mounted")
    // console.log("dc: ", dc);

    const usepublicdata = PUBLICDATA;
    console.log("usepublicdata: ", usepublicdata);

    fetch("https://flfddr.api.datexis.com" + (usepublicdata ? '/api/csv-publications-public' : '/api/csv-publications?token=' + token))
      .then((response) => response.json())
      .then((data, tableData) => {
        // prepare the data and initialise crossfilter

        // we set data as an instance attribute
        this.data = data;
        console.log('data: ', this.data)
        //this.logComparedata();

        this.initialAuthors = this.data.reduce((acc, curr) => {
          acc.add(curr.protagonistLabel);
          return acc;
        }, new Set());
        console.log("initialAuthors: ", this.initialAuthors);
        this.currentActivePersons = this.initialAuthors;

        // Initialize Crossfilter with the fetched data
        this.cf = crossfilter(this.data);

        // the reducers to be passed to all chart creator functions (once all functions have been created in SharedChartCreators)
        this.reducersForPublications = {
          reduceCountsAdd: this.reduceCountsAdd,
          reduceCountsRemove: this.reduceCountsRemove,
          reduceCountsInitial: this.reduceCountsInitial
        };


        // Show charts in view when the page is loaded 
          // and charts are open (value 'true' in chartIsOpen map)
        Object.keys(this.chartIsOpen).filter(key => this.chartIsOpen[key]).forEach(key => this.toggleChart(key))

        // Render charts
        dc.renderAll();
        this.tableData = this.data

        // TODO: add listener that reacts to the single filter events that occur while interacting with the visualisation
        //  here, we can update the table view of the respective data
        // see: https://stackoverflow.com/questions/22392134/is-there-a-way-to-attach-callback-what-fires-whenever-a-crossfilter-dimension-fi
        dc.chartRegistry.list().forEach(chart => {
          if (this.mycharts.indexOf(chart) != -1) {
            console.log("Publications: preparing for filtered listening: owner is Publications. Set listener", chart);
            chart.on("filtered", () => {
              this.onChartFiltered();
            });
          }
          else {
            console.log("Publications: preparing for filtered listening: owner is not Publications. Do not set listener", chart);
          }
        });

        // we update (rather, initialise) the external filters view
        this.renderExternalFiltersView();
      });
  }
};

</script>

