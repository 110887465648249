import * as dc from "dc";
import * as functions from "@/lib/SharedFunctions";
import * as d3 from "d3";

// NOTE THAT NONE OF THE FUNCTIONS WILL CALL render(). THIS IS UP TO THE FUNCTION's CALLER

function createBarChart(chartGroup, crossfilter, parentel, attrForDimension, reducers, valueAccessorFunction, axes, margins = {},options = {}) {
    console.log("createBarChart(): crossfilter: ", crossfilter);
    const attrDimension = crossfilter.dimension((d) => d[attrForDimension]);
    const attrGroup = attrDimension.group().reduce(reducers.reduceCountsAdd, reducers.reduceCountsRemove, reducers.reduceCountsInitial);
    const barChart = new dc.BarChart(parentel, chartGroup); // Use 'new' to create the chart instance

    // TODO: if we have a filter state, we set the chart as additional property in order to allow resetting it
    //  without having to interact with it
    // if (options.filterState) {
    //     options.filterState.chart = barChart
    // }

    barChart
        .dimension(attrDimension)
        .group(attrGroup)
        .colorAccessor(d => d.key)
        .colors(d => functions.stringToColour(d))
        .valueAccessor((d) => {
            return valueAccessorFunction(d);
        })
        .x(d3.scaleBand())
        .xUnits(dc.units.ordinal)
        .elasticX(options.elasticX || options.topN)
        .elasticY(true)
        .barPadding(0.1)
        .width(options.width || 600)
        .height(options.height || 400)
        //.render()
        .on('filtered.localFilterState', function(chart, filter) {
            console.log("got filter on bar chart: ", chart, filter,options);
            // check whether we have a filter state: if we have one, we know
            // that the state will be represented as a set of currently filtered-in values

            // currently, the value of filter state is a set or another object (for the timeline)
            // on which the filtered items (which have been selected, e.g., in a bar chart will be
            // set or removed. If we want to allow an easy "reset" of local filters, we need to pass
            // an object, e.g. of the form {data: <data>, chart: <chart>}, where <data> is the object
            // we currently have as filterState, and <chart> is the current chart. Based on this data structure
            // the reset could be implemented by accessing the chart object

            // TODO: if filterState is extended for easy resetting, we need to access the "data" property
            //  on filterState
            // if (options.filterState) {
            //     if (options.filterState.data.has(filter)) {
            //         options.filterState.data.delete(filter);
            //     }
            //     else {
            //         options.filterState.data.add(filter);
            //     }
            // }
            if (options.filterState) {
                if (filter === null) {
                    console.log("got null filter. Probably, the chart has been reset from a local filters card. Reset filter state.");
                    options.filterState.clear();
                }
                else if (options.filterState.has(filter)) {
                    options.filterState.delete(filter);
                }
                else {
                    options.filterState.add(filter);
                }
            }
        })
        .on("renderlet",() => {
            console.log("renderl")
            if (options.topN) {
                functions.createTopNOnRenderletListener(attrGroup,options.topN,valueAccessorFunction,parentel,options.with || 600,
                    ((margins.left || 50) + (margins.right || 0)))();
            }
        })
        .xAxisLabel(axes.xLabel)
        .yAxisLabel(axes.yLabel)
        .margins({top: margins.top || 30, right: margins.right || 0, bottom: margins.bottom || 100, left: margins.left || 50})
        .xAxis()
        .ticks(options.ticks || 50);

    return barChart;
}

function createPieChart(chartGroup, parentelement, attrForDimension, attrGroup, valueAccessorFunction, options = {}) {

    console.log("creatPieChart(): ", options, options.cap);
    //
    const pieChart = new dc.PieChart(parentelement, chartGroup);

    pieChart
        .dimension(attrForDimension)
        .group(attrGroup)
        .colorAccessor(d => d.key)
        .colors(d => functions.stringToColour(d))
        // if the crossfilter reduce function creates a result with a complex value object
        // we need a valueAccessor in order to pass the actual value to be displayed to the chart
        .valueAccessor((d) => {
          return valueAccessorFunction(d);
        })
        .width(400) // Set the width of the Pie Chart
        .height(400) // Set the height of the Pie Chart
        .on('filtered.localFilterState', function(chart, filter) {
            console.log("filtered.localFilterState: got filter on pie chart: ", filter,Array.isArray(filter));

            console.log("got filter on bar chart: ", chart, filter,options);
            // check whether we have a filter state: if we have one, we know
            // that the state will be represented as a set of currently filtered-in values
            if (options.filterState) {
                if (filter === null) {
                    console.log("got null filter. Probably, the chart has been reset from a local filters card. Reset filter state.");
                    options.filterState.clear();
                }
                else {
                    // this is for handling the "others" case in pie charts (in bar charts we do not have "others" so far) -
                    // here, the value of filter is a singleton array whose element is an array with the values behind "other"
                    if (!Array.isArray(filter)) {
                        filter = [filter];
                    }
                    else {
                        filter = filter[0];
                    }
                    filter.forEach(filtervalue => {
                        if (options.filterState.has(filtervalue)) {
                            options.filterState.delete(filtervalue);
                        } else {
                            options.filterState.add(filtervalue);
                        }
                    });
                }
            }
        })
        .ordering(d => {return -valueAccessorFunction(d)})
        .cap(options.cap || Infinity );
        // .render();

    return pieChart;
}

function createTimelineChart (chartGroup, parentelement, attrForDimension, attrGroup, valueAccessorFunction, filteredData,options = {}) {

    console.log("createTimelineChart(): options are: ", options);

    const minDate = filteredData.map(e => new Date(e.begin, 1, 1).getTime()).reduce((acc, curr) => Math.min(acc, curr), Infinity);
    const maxDate = filteredData.map(e => new Date(e.begin, 1, 1).getTime()).reduce((acc, curr) => Math.max(acc, curr), -Infinity);

    // create the chart
    const timelinechart = new dc.BarChart(parentelement, chartGroup);

    timelinechart
        .dimension(attrForDimension)
        .group(attrGroup)
        .x(d3.scaleTime())
        .valueAccessor((d) => {
        return valueAccessorFunction(d)
        })
        .width(768)
        .height(480)
        .xUnits(d3.timeYears)
        .elasticY(true)
        .clipPadding(10)
        // .render()
        .on('filtered.localFilterState', function(chart, filter) {
            console.log("got filter on timeline chart: ", chart, filter);

            // check whether we have a filter state: if we have one, we know
            // that the state will be represented as a set of currently filtered-in values

            // note that, here, no special handling of the external reset from the local filter view cards is necessary
            // as the case that filter == null is already handled
            if (options.filterState) {
                if (filter) {
                    options.filterState.from = filter[0],
                    options.filterState.to = filter[1]
                }
                else {
                    options.filterState.from = null,
                    options.filterState.to = null
                }
            }
        })
        // .render()

    timelinechart.x().domain([minDate, maxDate])
    timelinechart.yAxis().tickFormat(d3.format('.3s'));

    return timelinechart;
}

export {
    createBarChart,
    createPieChart,
    createTimelineChart
}
