<template>
  <div :class="{'table-hidden' : !isTableVisible}">
    <h1>Events View</h1>

    <div class="filter-and-charts-container">
      <div>
        <h2>Filters</h2>
      <div class="filter-container">
        <div>
          <h3 class="filter-head">External</h3>
          <div ref="externalFiltersRoot" />
        </div>
        <div style="width: 350px;">
          <div style="display:inline-flex;">
            <h3 class="filter-head">Local</h3>
            <div v-if="Object.keys(localFilterState).length > 1" class="remove-filter-btn" @click="removeFilters">Remove all</div>
          </div>
          <div ref="localFiltersRoot"/>
        </div>
      </div>
    </div>

    <div ref="chartsRoot" class="chart-container">
      <div style="display:flex">
        <h2>Charts</h2>
        <div class="active-persons">Currently active persons: {{currentActivePersons.size }}</div>
      </div>

      <div><button class="btn-show-chart" v-for="chartId in chartOrder" v-bind:key="chartId" v-html="chartTitles[chartId]"
          @click="showOrRemoveChartInView(chartId)" :class="{ active: isOpen(chartId) }"></button>
        <!-- Button to toggle table visibility -->
        <button @click="isTableVisible = !isTableVisible" class="btn-show-chart">
          {{ isTableVisible ? 'Hide Table' : 'Show Table' }}
        </button>
      </div>
      <br />

      <div class="flex-container">
        <div v-for="chartId in Object.keys(chartIsOpen).filter(key => chartIsOpen[key])" v-bind:key="chartId">
          <div v-if="chartId == 'repository-charts'" :open="isOpen(chartId)">
            <div v-html="chartTitles[chartId]" id='repository-charts'/>
            <details id="repository-1" open>
              <summary  v-html="subtitlesForCollectionCharts['repository-1']"/>
            </details>
            <details id="repository-2" open>
              <summary v-html="subtitlesForCollectionCharts['repository-2']"/>
            </details>
            <details id="repository-3" open>
              <summary v-html="subtitlesForCollectionCharts['repository-3']"/>
            </details>
          </div>
          <div v-if="chartId == 'professions-charts'" :open="isOpen(chartId)" >
            <div v-html="chartTitles[chartId]" id='professions-charts'/>
            <details id="profession-1" open>
              <summary v-html="subtitlesForCollectionCharts['profession-1']"></summary>
            </details>
            <details id="profession-2" open>
              <summary v-html="subtitlesForCollectionCharts['profession-2']"></summary>
            </details>
            <details id="profession-3" open>
              <summary v-html="subtitlesForCollectionCharts['profession-3']"></summary>
            </details>
          </div>
          <div v-if="chartId == 'institutions-charts'" :open="isOpen(chartId)">
            <div v-html="chartTitles[chartId]" id='institutions-charts'/>
            <details id="institution-1" open>
              <summary v-html="subtitlesForCollectionCharts['institution-1']"></summary>
            </details>
            <details id="institution-2" open>
              <summary v-html="subtitlesForCollectionCharts['institution-2']"></summary>
            </details>
            <details id="institution-3" open>
              <summary v-html="subtitlesForCollectionCharts['institution-3']"></summary>
            </details>
          </div>
          <div v-if="chartId !== 'repository-charts' && chartId !== 'professions-charts' && chartId !== 'institutions-charts'" :open="isOpen(chartId)">
            <div v-html="chartTitles[chartId]"></div>
            <div :id="chartId" />
        </div>
        </div>
      </div>
    </div>
  </div>

    <!-- Table Section, visible based on isTableVisible -->
  <div v-show="isTableVisible" ref="tableRoot" class="table-container">
    <div>
      <span>Search value: </span>
      <input type="text" v-model="searchTerm">
    </div>

    <!-- EasyDataTable -->
    <EasyDataTable ref="easyDataTable" buttons-pagination alternating multi-sort :headers="tableHeaders"
      :items="tableData" :items-per-page="itemsPerPage" :search-value="searchTerm" :sort-by="sortBy" :sort-type="sortType"
      v-model:page="currentPage">
    </EasyDataTable>
  </div>
  </div>
</template>

<script>

import * as dc from 'dc';
//import * as d3 from 'd3';
import * as dcleaflet from 'dc.leaflet'
import crossfilter from 'crossfilter2';
import 'leaflet/dist/leaflet.css'; // Import Leaflet CSS
import L from 'leaflet';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet.markercluster';
import './css/dc.css';
import './css/main.css';
//import {lineChart} from "dc";
//import {render} from "vue";
//import * as functions from "@/lib/SharedFunctions";
import {PUBLICDATA} from "@/lib/SharedConstants";
import * as effunctions from "@/lib/SharedExternalFiltersFunctions"
import * as charts from "@/lib/SharedChartCreators"
import { EventBus } from '../lib/EventBus';
import EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';

let instancecount = 0;

/* eslint-disable no-unused-vars */
export default {
  name: 'EventsView',
  components: {
    EasyDataTable,
  },
  data() {
    return {
      cf: null,
      mycharts: [],
      markers: null,
      allEvents: [],
      pendingFilteredPersonsFromPublication: null,
      externalFilterApplied: false,
      isTableVisible: true,

      tableData: [],
      searchReasults: null,
      searchTerm: '',
      currentPage: 1,
      itemsPerPage: 15,
      tableHeaders: [
        { text: 'ID', value: 'id', sortable: true },
        { text: 'Begin', value: 'begin', sortable: true },
        { text: 'End', value: 'end', sortable: true },
        { text: 'Event', value: 'type', sortable: true },
        { text: 'Author', value: 'protagonistLabel', sortable: true },
        { text: 'Gender', value: 'protagonistGender', sortable: true },
        { text: 'Place', value: 'placeLabel', sortable: true },
        { text: 'Term', value: 'literariyActivity', sortable: true },
        { text: 'BA L1', value: 'baLvl1' },
        { text: 'BA L2', value: 'baLvl2' },
        { text: 'BA L3', value: 'baLvl3' },
        { text: 'BA L4', value: 'baLvl4' },
        { text: 'BA Q', value: 'qualificationLvl' },
        { text: 'Institution', value: 'institutionLabel' },
        { text: 'Inst L1', value: 'institutionTypeLv1' },
        { text: 'Inst L2', value: 'institutionTypeLv2' }
      ],
      sortBy: [],
      sortType: ["asc", "desc"],
      chartOrder: [
        'chart-gender',
        'repository-charts',
        'piechart-for-persons',
        'piechart-for-events',
        'barchart-events-person',
        'professions-charts',
        'institutions-charts',
        'timeline-container-events',
        'timeline-container-persons',
        'map-container'
      ],
      chartTitles: {
        'chart-gender': 'Gender',
        'repository-charts': 'Repository Charts',
        'piechart-for-persons': 'Number of Persons by Event Type',
        'piechart-for-events': 'Number of Events by Event Type',
        'barchart-events-person': 'Number of Events by Persons',
        'professions-charts': 'Professions charts',
        'institutions-charts': 'Institutions charts',
        'timeline-container-events': 'Timeline with Number of Events',
        'timeline-container-persons': 'Timeline with Number of Persons',
        'map-container': 'Map with Event Locations'
      },
      chartIsOpen: {
        'chart-gender': false,
        'repository-charts': false,
        'piechart-for-persons': false,
        'piechart-for-events': false,
        'barchart-events-person': false,
        'professions-charts': false,
        'institutions-charts': false,
        'timeline-container-events': false,
        'timeline-container-persons': true,
        'map-container': false
      },
      subtitlesForCollectionCharts: {
        'repository-1': 'Repository Types by Events',
        'repository-2': 'Repository Names by Events',
        'repository-3': 'Persons by Repository Names',
        'profession-1': 'Qualification of Activities by Persons',
        'profession-2': 'Areas of Activities by Persons',
        'profession-3': 'Professions by Persons',
        'institution-1': 'Institution types by Persons',
        'institution-2': 'Institution subtypes by Persons',
        'institution-3': 'Institutions by Persons',
      },
      chartsVisibility: {
        'repository-charts': {
          // the creator functions need to return the chart object in order for toggleChart() to work
          creatorfunc: () => this.createChartsForReferences(),
        },
        'piechart-for-persons': {
          creatorfunc: () => this.createPieChartForPersons(),
        },
        'piechart-for-events': {
          creatorfunc: () => this.createPieChartForEvents(),
        },
        'chart-gender': {
          creatorfunc: () => this.createBarChartForGender(),
        },
        'barchart-events-person': {
          creatorfunc: () => this.createBarChartEventsForPersons(),
        },
        'professions-charts': {
          creatorfunc: () => this.createChartsForProfessionsEvaluation(),
        },
        'institutions-charts': {
          creatorfunc: () => this.createChartsForInstitutions(),
        },
        'timeline-container-events': {
          creatorfunc: () => this.createTimelineChartFromEventsWithEventCount(),
        },
        'timeline-container-persons': {
          creatorfunc: () => this.createTimelineChartFromEventsWithPersonCount(),
        },
        'map-container': {
          creatorfunc: () => {
            console.log("map-container: component id is: ", this.componentid);
            // for the time being, there is an issue with maps, which canno be created multiply
            if (instancecount === 1) {
              return this.createMapchartForLocations()
            }
            else {
              // we create a dummy return value that supports the methods expected
              return {
                render: () => {return {}},
                on: () => {return {}}
              }
            }
          }
        }
      },
    };
  },
  methods: {
    toggleChart(chartID) {
      // check whether the chart has already been created
      const chartSelector = "#" + chartID + " svg";
      const chartElement = this.chartsRoot.querySelector(chartSelector);
      // console.log("toggleChart(): chartsVisibility: ", this.chartsVisibility, chartID);
      // console.log("toggleChart(): got element for selector: ", chartSelector, chartElement);
      if (!chartElement) {
        // console.log("toggleChart(): will create chart for: ", chartID, this.chartsVisibility[chartID]);
        // 1. call the creator function for which the chart has been registered - it might either return
        // a single chart or an array of charts
        let chartOrCharts = this.chartsVisibility[chartID].creatorfunc();
        if (!Array.isArray(chartOrCharts)) {
          chartOrCharts = [chartOrCharts];
        }
        // console.log("toggleCharts(): got charts: ", chartOrCharts);
        chartOrCharts.forEach(chart => {
          // 2. set the filtered listener on the chart
          chart.on("filtered", () => this.onChartFiltered());
          // 3. if the creator function cannot call render() at the very end, we might need to call it here
          //console.log("toggleCharts(): render: ", chart);
          chart.render();
        })
      }
      localStorage.setItem(this.componentid + ".chartsVisibility", JSON.stringify(this.chartsVisibility));
    },
    isOpen(chartID) {
      if (this.chartIsOpen[chartID] == true) {
        return chartID
      }
    },
    showOrRemoveChartInView(chartID) {
      if (this.chartIsOpen[chartID] == true) {
        //console.log('try to remove chart');
        document.getElementById(chartID).parentNode.parentNode.remove();
        this.chartIsOpen[chartID] = false;
      }
      else {
        this.chartIsOpen[chartID] = true
        setTimeout(() => {
          // Code to be executed after the delay
          //console.log('Action after 2 seconds');
          this.toggleChart(chartID)
        }, 10);
      }
    },
    removeFilters() {
      this.localFilterState = {};
      this.renderLocalFiltersView();
      this.externalFilters.forEach(filter => {filter.active = false});
      this.renderExternalFiltersView();
      dc.filterAll(this.componentid);
    },
    extractLocationsFromEvents(events) {
      return Object.values(events
        .filter(evt => evt.latlong.length > 0)
        .reduce((acc, curr) => {
          if (!acc[curr.placeId]) {
            acc[curr.placeId] = {
              placeId: curr.placeId,
              placeLabel: curr.placeLabel,
              latlong: curr.latlong,
              events: {}
            }
          }
          acc[curr.placeId].events[curr.id] = curr;
          return acc;
        }, {}));
    },

    /* start modifying reducer functions as in publications */

    /* reducer functions for calculating groups based on the number of unique events and unique persons */
    createKeyForReducer(dataitem) {
      return "_" + dataitem.type
        + "_" + dataitem.begin
        + "_" + dataitem.placeId
        + "_" + dataitem.protagonistLabel
        + "_" + dataitem.protagonistGender
        + "_" + dataitem.baLvl1
        + "_" + dataitem.baLvl4
        + "_" + dataitem.qualificationLvl
        + "_" + dataitem.reponame
        + "_" + dataitem.repotype
        + "_" + dataitem.institutionLabel
        + "_" + dataitem.institutionTypeLv1
        + "_" + dataitem.institutionTypeLv2
        + "_";
    },
    reduceCountsAdd(acc, curr) {
      // we create keys that concatenate, for each element in the data set, the values
      // of the filter dimensions, we are interested in and use these concatenated strings
      // as keys in the object to be created and manipulated by the reduce functions
      const key = this.createKeyForReducer(curr);

      if (!acc[key]) {
        acc[key] = {
          uniqueEvents: new Set(),
          uniquePersons: new Set(),
          uniqueReponames: new Set()
        }
      }
      acc[key].uniqueEvents.add(curr.id);
      acc[key].uniquePersons.add(curr.protagonistLabel);
      acc[key].uniqueReponames.add(curr.reponame);

      return acc;
    },

    reduceCountsRemove(acc, curr) {
      const key = this.createKeyForReducer(curr);

      acc[key].uniqueEvents.delete(curr.id);
      acc[key].uniquePersons.delete(curr.protagonistLabel);
      acc[key].uniqueReponames.delete(curr.reponame);

      return acc;
    },

    reduceCountsInitial() {
      // console.log("reduceEventIdsInitial()")
      return {}
    },

    /* methods for calculating the numbers given the values created by the reducer functions */
    getEventsCountFromReducedValue(d) {
      return Object.values(d.value)
        .map(val => val.uniqueEvents)
        .reduce((acc, curr) => {
          curr.forEach(el => acc.add(el));
          return acc;
        }, new Set()).size;
    },

    getPersonsCountFromReducedValue(d) {
      return Object.values(d.value)
        .map(val => val.uniquePersons)
        .reduce((acc, curr) => {
          curr.forEach(el => acc.add(el));
          return acc;
        }, new Set()).size;
    },

    getRepositoryCountFromReducedValue(d) {
      return Object.values(d.value)
        .map(val => val.uniqueReponames)
        .reduce((acc, curr) => {
          curr.forEach(el => acc.add(el));
          return acc;
        }, new Set()).size;
    },


    /* methods for creating the charts */

    createMapchartForLocations() {
      const eventsWithLocations = this.allEvents.filter(event => event.latlong.length > 0);
      console.log("eventsWithLocations: ", eventsWithLocations);
      const locationsWithEvents = this.extractLocationsFromEvents(this.data);
      console.log("locationsWithEvents: ", locationsWithEvents);
      const events2LocationMap = locationsWithEvents.reduce((acc, curr) => {
        Object.keys(curr.events).forEach(evtid => {
          acc[evtid] = curr;
        });
        return acc;
      }, {});

      console.log("events2LocationMap:", events2LocationMap);

      console.log("dcleaflet: ", dcleaflet);
      console.log("L object from leaflet: ", L);

      // currently, dc leatflet gets into big trouble unless there are either NO coordinates
      // or coordinates for ALL data items, so we use antarctica as a dummy...
      const dummyCoordinates = "-73.64717144581896, 42.68994421727224";

      var geodimension = this.cf.dimension(function (d) {
        const loc = events2LocationMap[Number(d.id)];
        if (Number(d.id) % 1 === 0 && loc && loc.latlong) {
          // console.log("found event with location and geocode: ", d, loc, loc.latlong);
          if (loc.latlong.split(",").length === 2) {
            return loc.latlong;
          } else {
            console.log("found invalid coodinates: ", loc.latlong);
            return dummyCoordinates;
          }
        }
        return dummyCoordinates;
      });

      var geodimensionGroup = geodimension.group().reduceCount();

      // in the end, we should not use ids anymore, but classes, because semantics of ids is corrupted
      // as soon as there is more than one tab for events or publications
      const mapContainer = this.chartsRoot.querySelector("#map-container");

      const leafletMarkerChart = new dcleaflet.markerChart(mapContainer);
      console.log("created leafletMarkerChart: ", leafletMarkerChart);
      this.mycharts.push(leafletMarkerChart);

      leafletMarkerChart
        .dimension(geodimension)
        .group(geodimensionGroup)
        .width(600)
        .height(400)
        .renderLabel(true)
        .renderTitle(true)
        .center([42.69, 25.42])
        .zoom(7)
        .cluster(true)
        .filterByArea(true)
          .on('filtered.localFilterState', function(chart, filter) {
            console.log("got filter on map chart: ", chart, filter);
            // TODO: here, for the time being we can only add the mere information THAT some custom filtering has been done
            //  e.g: mapChart: filtered area
          })
        .icon(function (d) {
          // for some reason, the Default() icon does not work
          const icon = new L.Icon({
            iconUrl: "https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-blue.png"
          });
          return icon;
        }
        )

      return leafletMarkerChart;
    },

    createBarChartForGender() {
      const eventReducers = {
        reduceCountsInitial: this.reduceCountsInitial,
        reduceCountsAdd: this.reduceCountsAdd,
        reduceCountsRemove: this.reduceCountsRemove
      };
      // TODO: for easily resetting the local filters, we can pass
      //  a "localFilterState" object that included information
      //  on the filtered values ("data") and the chart itself ("chart")
      //  the chart property will be set by the chart creator
      //  this.localFilterState.personsByGender = {
      //   data: new Set(),
      //   chart: null
      //  }
      this.localFilterState.personsByGender = new Set();
      console.log('createBarChartForGender chartsRoot', this.chartsRoot.querySelector("#chart-gender"))
      const chart = charts.createBarChart(this.componentid, this.cf,
        this.chartsRoot.querySelector("#chart-gender"),
        "protagonistGender",
        eventReducers,
        this.getPersonsCountFromReducedValue,
        {
          xLabel: "Gender",
          yLabel: "Number of People"
        },
        { bottom: 50 },
        { filterState: this.localFilterState.personsByGender }
      );
      this.mycharts.push(chart);
      return chart;
    },

    createPieChartForPersons() {
      const eventTypeDimension = this.cf.dimension((d) => d.type);
      const eventTypeGroupByeventsCount = eventTypeDimension.group().reduce(this.reduceCountsAdd, this.reduceCountsRemove, this.reduceCountsInitial);
      this.localFilterState.eventByPersons = new Set()

      const chart = charts.createPieChart(this.componentid, 
        this.chartsRoot.querySelector("#piechart-for-persons"),
        eventTypeDimension,
        eventTypeGroupByeventsCount,
        this.getPersonsCountFromReducedValue,
        { filterState: this.localFilterState.eventByPersons }
      );
      this.mycharts.push(chart);
      return chart;
    },

    createPieChartForEvents() {
      const eventTypeDimension = this.cf.dimension((d) => d.type);
      const eventTypeGroupByeventsCount = eventTypeDimension.group().reduce(this.reduceCountsAdd, this.reduceCountsRemove, this.reduceCountsInitial);
      this.localFilterState.eventByEvents = new Set()

      const chart = charts.createPieChart(this.componentid, 
        this.chartsRoot.querySelector("#piechart-for-events"),
        eventTypeDimension,
        eventTypeGroupByeventsCount,
        this.getEventsCountFromReducedValue,
        { filterState: this.localFilterState.eventByEvents }
      );
      this.mycharts.push(chart);
      return chart;

    },

    createTimelineChartFromEventsWithEventCount() {
      // this array is only used for determining the minData and maxDate
      const eventsWithDate = this.data.filter(evt => evt.begin);

      // the date dimension: we start using single years rather than year intervals
      const dateDimension = this.cf.dimension(event => {
        if (event.begin) {
          return new Date(event.begin, 1, 1);
        }
        else {
          return new Date(2100, 1, 1);
        }
      });

      // the grouping for the date dimension: unique persons and events by year
      const eventsByYearGroup = dateDimension.group()
        .reduce(this.reduceCountsAdd, this.reduceCountsRemove, this.reduceCountsInitial);

      this.localFilterState.timelineByNumberOfEvents = {
        from: null,
        to: null
      };
      const chart = charts.createTimelineChart(this.componentid, 
        this.chartsRoot.querySelector('#timeline-container-events'),
        dateDimension,
        eventsByYearGroup,
        this.getEventsCountFromReducedValue,
        eventsWithDate,
        { filterState: this.localFilterState.timelineByNumberOfEvents }
      );
      this.mycharts.push(chart);
      return chart;
    },

    createTimelineChartFromEventsWithPersonCount() {
      const eventsWithDate = this.data.filter(evt => evt.begin);

      // the date dimension: we start using single years rather than year intervals
      const dateDimension = this.cf.dimension(event => {
        if (event.begin) {
          return new Date(event.begin, 1, 1);
        }
        else {
          return new Date(2030, 1, 1);
        }
      });

      // the grouping for the date dimension: unique persons and events by year
      const eventsByYearGroup = dateDimension.group()
        .reduce(this.reduceCountsAdd, this.reduceCountsRemove, this.reduceCountsInitial);

      this.localFilterState.timelineByEventsWithPersonCount = {
        from: null,
        to: null
      };
      const chart = charts.createTimelineChart(this.componentid, 
        this.chartsRoot.querySelector('#timeline-container-persons'),
        dateDimension,
        eventsByYearGroup,
        this.getPersonsCountFromReducedValue,
        eventsWithDate,
        { filterState: this.localFilterState.timelineByEventsWithPersonCount }
      );
      this.mycharts.push(chart);
      return chart;
    },

    logComparedata() {
      const compareFemale = this.data.filter(d => d.protagonistGender === "FEMALE")
        .reduce((acc, curr) => {
          acc.uniqueEventIds.add(curr.protagonistLabel);
          acc.uniqueEventIds.add(curr.id);
          if (!acc["author_is_" + curr.protagonistGender]) {
            acc["author_is_" + curr.protagonistGender] = new Set();
          }
          acc["author_is_" + curr.protagonistGender].add(curr.protagonistLabel)
          if (!acc["female_with_eventtype_" + curr.type]) {
            acc["female_with_eventtype_" + curr.type] = new Set();
          }
          acc["female_with_eventtype_" + curr.type].add(curr.id)
          if (!acc["female_with_eventtype_" + curr.type + "_in_year_" + curr.begin]) {
            acc["female_with_eventtype_" + curr.type + "_in_year_" + curr.begin] = new Set();
          }
          acc["female_with_eventtype_" + curr.type + "_in_year_" + curr.begin].add(curr.id)
          return acc;
        }, { uniqueEventIds: new Set() });

      const compareEducation = this.data.filter(d => d.type === "Education")
        .reduce((acc, curr) => {
          acc.uniqueEventIds.add(curr.protagonistLabel);
          acc.uniqueEventIds.add(curr.id);
          if (!acc["education_with_gender_" + curr.protagonistGender]) {
            acc["education_with_gender_" + curr.protagonistGender] = new Set();
          }

          acc["education_with_gender_" + curr.protagonistGender].add(curr.protagonistLabel)
          if (!acc["education_with_gender_" + curr.protagonistGender + "_in_year_" + curr.begin]) {
            acc["education_with_gender_" + curr.protagonistGender + "_in_year_" + curr.begin] = new Set();
          }
          acc["education_with_gender_" + curr.protagonistGender + "_in_year_" + curr.begin].add(curr.id)

          return acc;
        }, { uniqueEventIds: new Set() });


      //console.log("compareFemale:", compareFemale);
      //console.log("compareEducation:", compareEducation)
    },

    createBarChartEventsForPersons() {
      const eventReducers = {
        reduceCountsInitial: this.reduceCountsInitial,
        reduceCountsAdd: this.reduceCountsAdd,
        reduceCountsRemove: this.reduceCountsRemove
      };
      this.localFilterState.eventsForPersons = new Set()
      const chart = charts.createBarChart(this.componentid, this.cf,
        this.chartsRoot.querySelector("#barchart-events-person"),
        "protagonistLabel",
        eventReducers,
        this.getEventsCountFromReducedValue,
        {
          xLabel: "People",
          yLabel: "Number of Events"
        },
        { top: 30, right: 0, bottom: 100, left: 50 },
        { topN: 15, filterState: this.localFilterState.eventsForPersons });
      this.mycharts.push(chart);
      return chart;
    },

    createChartsForReferences() {
      const eventReducers = {
        reduceCountsInitial: this.reduceCountsInitial,
        reduceCountsAdd: this.reduceCountsAdd,
        reduceCountsRemove: this.reduceCountsRemove
      };

      this.localFilterState.referencesEvaluation = new Set()

      const chartContainer1 =  this.chartsRoot.querySelector("#repository-1").appendChild(Object.assign(document.createElement("div"), {id : 'repotypes-by-events-container', className: "dhvis-rotated-ticks"}));
      const chart1 = charts.createBarChart(this.componentid, this.cf,
      chartContainer1,
        "repotype",
        eventReducers,
        this.getEventsCountFromReducedValue,
        { xLabel: "Bestandstyp", yLabel: "Anzahl Events" },
        { bottom: 100, elasticX: true },
        { filterState: this.localFilterState.referencesEvaluation }
      );
      this.mycharts.push(chart1);

      const chartContainer2 =  this.chartsRoot.querySelector("#repository-2").appendChild(Object.assign(document.createElement("div"), {id : 'reponames-by-events-container', className: "dhvis-rotated-ticks"}));
      const chart2 = charts.createBarChart(this.componentid, this.cf,
        chartContainer2,
        "reponame",
        eventReducers,
        this.getEventsCountFromReducedValue,
        {
          xLabel: "Bestand",
          yLabel: "Anzahl Events"
        },
        { bottom: 200 },
        { topN: 15, filterState: this.localFilterState.referencesEvaluation }
      );
      this.mycharts.push(chart2);

      const chartContainer3 =  this.chartsRoot.querySelector("#repository-3").appendChild(Object.assign(document.createElement("div"), {id : 'persons-by-reponames-container', className: "dhvis-rotated-ticks"}));
      const chart3 = charts.createBarChart(this.componentid, this.cf,
        chartContainer3,
        "protagonistLabel",
        eventReducers,
        this.getRepositoryCountFromReducedValue,
        {
          xLabel: "Person",
          yLabel: "Anzahl Bestaende"
        },
        { bottom: 100 },
        { topN: 15, filterState: this.localFilterState.referencesEvaluation }
      );
      this.mycharts.push(chart3);

      return [chart1, chart2, chart3];
    },

    createChartsForProfessionsEvaluation() {
      const eventReducers = {
        reduceCountsInitial: this.reduceCountsInitial,
        reduceCountsAdd: this.reduceCountsAdd,
        reduceCountsRemove: this.reduceCountsRemove
      };

      // the value accesor return 0 if the key of the respective data is "" - which is the case
      // if an event does not contain any information regarding professions
      const professionsValueAccessorForPersonsCount = (d) => {
        if (d.key == "") {
          return 0;
        }
        else {
          return this.getPersonsCountFromReducedValue(d);
        }
      };

      this.localFilterState.professionsEvaluation = new Set()

      const chartContainer1 =  this.chartsRoot.querySelector("#profession-1").appendChild(Object.assign(document.createElement("div"), {id : 'ba-quallvl-container', className: "dhvis-rotated-ticks"}));
      const chart1 = charts.createBarChart(this.componentid, this.cf,
        chartContainer1,
        "qualificationLvl",
        eventReducers,
        professionsValueAccessorForPersonsCount,
        {
          xLabel: "Qualifikation",
          yLabel: "Anzahl Personen"
        },
        { bottom: 200 },
        { filterState: this.localFilterState.professionsEvaluation }
      );
      this.mycharts.push(chart1);

      const chartContainer2 = this.chartsRoot.querySelector("#profession-2").appendChild(Object.assign(document.createElement("div"), {id : 'ba-lvl1-container', className: "dhvis-rotated-ticks"}));
      const chart2 = charts.createBarChart(this.componentid, this.cf,
        chartContainer2,
        "baLvl1",
        eventReducers,
        professionsValueAccessorForPersonsCount,
        {
          xLabel: "Berufsfelder",
          yLabel: "Anzahl Personen"
        },
        { bottom: 200 },
        { filterState: this.localFilterState.professionsEvaluation }
      );
      this.mycharts.push(chart2);

      const chartContainer3 =  this.chartsRoot.querySelector("#profession-3").appendChild(Object.assign(document.createElement("div"), {id : 'ba-lvl4-container', className: "dhvis-rotated-ticks"}));
      const chart3 = charts.createBarChart(this.componentid, this.cf,
        chartContainer3,
        "baLvl4",
        eventReducers,
        professionsValueAccessorForPersonsCount,
        {
          xLabel: "Berufe",
          yLabel: "Anzahl Personen"
        },
        { bottom: 200 },
        { topN: 15, filterState: this.localFilterState.professionsEvaluation}
      );
      this.mycharts.push(chart3);

      return [chart1, chart2, chart3];
    },
    createChartsForInstitutions() {
      const eventReducers = {
        reduceCountsInitial: this.reduceCountsInitial,
        reduceCountsAdd: this.reduceCountsAdd,
        reduceCountsRemove: this.reduceCountsRemove
      };

      // the value accesor return 0 if the key of the respective data is "" - which is the case
      // if an event does not contain any information regarding professions
      const institutionsValueAccessorForPersonsCount = (d) => {
        if (d.key == "") {
          return 0;
        }
        else {
          return this.getPersonsCountFromReducedValue(d);
        }
      };

      // we might need three different states for the three different dimensions of institutions
      this.localFilterState.institutionsEvaluation = new Set();

      const chartContainer1 =  this.chartsRoot.querySelector("#institution-1").appendChild(Object.assign(document.createElement("div"), {id : 'inst-lv1-container', className: "dhvis-rotated-ticks"}));
      const chart1 = charts.createBarChart(this.componentid, this.cf,
          chartContainer1,
          "institutionTypeLv1",
          eventReducers,
          institutionsValueAccessorForPersonsCount,
          {
            xLabel: "Institutionstyp",
            yLabel: "Anzahl Personen"
          },
          { bottom: 200 },
          { filterState: this.localFilterState.institutionsEvaluation }
      );
      this.mycharts.push(chart1);

      const chartContainer2 =  this.chartsRoot.querySelector("#institution-2").appendChild(Object.assign(document.createElement("div"), {id : 'inst-lv2-container', className: "dhvis-rotated-ticks"}));
      const chart2 = charts.createBarChart(this.componentid, this.cf,
          chartContainer2,
          "institutionTypeLv2",
          eventReducers,
          institutionsValueAccessorForPersonsCount,
          {
            xLabel: "Untertyp",
            yLabel: "Anzahl Personen"
          },
          { bottom: 200 },
          { filterState: this.localFilterState.institutionsEvaluation }
      );
      this.mycharts.push(chart2);

      const chartContainer3 =  this.chartsRoot.querySelector("#institution-3").appendChild(Object.assign(document.createElement("div"), {id : 'inst-names-container', className: "dhvis-rotated-ticks"}));
      const chart3 = charts.createBarChart(this.componentid, this.cf,
          chartContainer3,
          "institutionLabel",
          eventReducers,
          institutionsValueAccessorForPersonsCount,
          {
            xLabel: "Institution",
            yLabel: "Anzahl Personen"
          },
          { bottom: 200 },
          { topN: 15, filterState: this.localFilterState.institutionsEvaluation}
      );
      this.mycharts.push(chart3);

      return [chart1, chart2, chart3];
    },
    onChartFiltered() {
      this.currentActivePersons = effunctions.getActivePersons(this.cf);
      // Update data for the tableData
      this.tableData = JSON.parse(JSON.stringify(this.cf.allFiltered()));

      // once a chart is filtered, we also need to update the local filters view
      this.renderLocalFiltersView();

      console.log("Events: emit filteredPersons", this.localFilterState);
      EventBus.emit('filteredPersons', {
        componentid: this.componentid,
        persons: Array.from(this.currentActivePersons),
        filterState: this.localFilterState
      });
      this.$refs.easyDataTable.updatePage(1)
    },
    removeLocalFilterCard(element, chart) {
    const filterAttributeToFilterState = {
        'Active external filters': 'activeExternalFilters',
        'Gender': "personsByGender",
        'Number of Persons by Event Type': 'eventByPersons',
        'Number of Events by Event Type': 'eventByEvents',
        'Number of Events by Persons': 'eventsForPersons',
        'Professions charts': 'professionsEvaluation',
        'Institutions charts': 'institutionsEvaluation',
        'Repository charts': 'referencesEvaluation', 
        'Timeline with Number of Events': 'timelineByEventsWithPersonCount',
        'Timeline with Number of Persons': 'timelineByNumberOfEvents',
      }

    const filterStateToChartRegisterAnchor = {
      "personsByGender": 'chart-gender',
      'eventByPersons': 'piechart-for-persons',
      'eventByEvents': 'piechart-for-events',
      'eventsForPersons': 'barchart-events-person',
      'institutionsEvaluation': ['inst-lv1-container', 'inst-lv2-container', 'inst-names-container' ],
      'professionsEvaluation': ['ba-quallvl-container', 'ba-lvl1-container', 'ba-lvl4-container' ],
      'referencesEvaluation': ['repotypes-by-events-container', 'reponames-by-events-container', 'persons-by-reponames-container'],
      'timelineByEventsWithPersonCount': 'timeline-container-persons',
      'timelineByNumberOfEvents': 'timeline-container-events',
      }

    // Remove the parent element (which is the filter card)
    element.parentNode.removeChild(element);
    const filterStateChart = filterAttributeToFilterState[chart.split(':')[0].trim()];

    // Update the chart: remove selected filter
    dc.chartRegistry.list(this.componentid).forEach(chartInRegistry => {
      var anchorID = chartInRegistry._anchor.id
      console.log('TESt:', chart, chartInRegistry, anchorID, filterStateToChartRegisterAnchor[filterStateChart], filterStateChart )
      if ((anchorID == filterStateToChartRegisterAnchor[filterStateChart]) || filterStateToChartRegisterAnchor[filterStateChart]?.includes(anchorID)) {
        // it seems that at least for pie charts, re-rendering is required after resetting the filter
        console.log("removeLocalFilterCard(): rendering chart: ", anchorID);
        chartInRegistry.filter(null).render();

        // Update all subcharts in collection
        if (filterStateChart == 'professionsEvaluation' || filterStateChart == 'referencesEvaluation' || filterStateChart == 'institutionsEvaluation'){
          filterStateToChartRegisterAnchor[filterStateChart].forEach(subchart => {
            dc.chartRegistry.list(this.componentid).forEach(subchartInRegistry => {
              if (chartInRegistry._anchor.id == subchart) {
                console.log("removeLocalFilterCard(): rendering subchart: ",subchartInRegistry._anchor.id);
                subchartInRegistry.filter(null).render();
              }
            })
          })
        }
      }
    });

    // Update apply/remove button of the external filter card
    if (chart.includes('external filter')) {
      const componentIdExternalFilter =  chart.split(':')[1];
      this.externalFilters.forEach(filter => {
        if (filter.componentid == componentIdExternalFilter.trim()) {
          filter.active = false}
      });
    this.renderExternalFiltersView();
    }},
    renderLocalFiltersView() {
      console.log("renderLocalFiltersView() eventView: ", this.localFiltersRoot, this.localFilterState);
      this.localFiltersRoot.innerHTML = "";
      const filterDetailsInfo = document.createElement("div");

      effunctions.collectMetadataFromExternalFilters({ filterState: this.localFilterState }).textContent.split(';').forEach(filter => {
        const divElement = Object.assign(document.createElement("div"), { id: filter});
        const pElement = Object.assign(document.createElement("p"), { textContent: filter , className: "local-filter-card"});
        const removeElement = Object.assign(document.createElement("span"), { textContent: 'x', className: "remove-each-local-filter-btn"});
        
        if (filter.trim() !== "") { // Check if the filter is not empty after trimming whitespaces
          removeElement.addEventListener("click", () => {
            this.removeLocalFilterCard(divElement, filter);
          });
          divElement.appendChild(removeElement);
          divElement.appendChild(pElement);
          filterDetailsInfo.appendChild(divElement);
        }
      })

      this.localFiltersRoot.appendChild(filterDetailsInfo);
    },
    renderExternalFiltersView() {
      // reset
      this.externalFiltersRoot.innerHTML = "";
      // iterate over the filters
      this.externalFilters.forEach(filter => {
        const filterArea = document.createElement("fieldset");
        console.log('filter.componentid: ', filter.componentid)
        filterArea.appendChild(Object.assign(document.createElement("legend"), { textContent: filter.componentid }));
        // for transparency, we display the state
        filterArea.appendChild(effunctions.filterStateInfo(filter));

        /// collect metadata from the external filters
        if (filter.filterState) {
          effunctions.collectMetadataFromExternalFilters(filter).textContent.split('; ').forEach(f => {
            filterArea.appendChild(Object.assign(document.createElement("div"), { textContent: f, className: 'div-ext-filters' }));
          })
        }

        // here's the main button - for applying/removing a filter
        console.log('renderExtFilterView filter active', filter)
        const filterMainAction = filterArea.appendChild(Object.assign(document.createElement("button"), { textContent: filter.active ? "Remove" : "Apply", className: "btn-apply" }))

        filterMainAction.onclick = () => {
          filter.active = !filter.active;
          const applyExternalFiltersArray = effunctions.applyExternalFilters(this.localFilterState, this.externalFilters, this.initialAuthors, this.externalPersonsDimension, this.currentActivePersons, this.cf, this.mycharts, this.componentid);
          this.externalPersonsDimension = applyExternalFiltersArray[0];
          this.currentActivePersons = applyExternalFiltersArray[1];
          // do what we need to do given the new state of the filter
          // (...)
          // refresh the filters view
          // we remove the updated attribute
          filter.updated = false;
          this.renderExternalFiltersView();
        }
        if (filter.active && filter.updated) {
          // an additional action if a filter is active and has been updated
          const filterAdditionalAction = filterArea.appendChild(Object.assign(document.createElement("button"), { textContent: "Re-apply", className: "btn-apply" }));
          filterAdditionalAction.onclick = () => {
            const applyExternalFiltersArray = effunctions.applyExternalFilters(this.localFilterState, this.externalFilters, this.initialAuthors, this.externalPersonsDimension, this.currentActivePersons, this.cf, this.mycharts, this.componentid);
            this.externalPersonsDimension = applyExternalFiltersArray[0];
            this.currentActivePersons = applyExternalFiltersArray[1];
            // do what we need to do, i.e. apply the new filter criteria
            // ... and refresh state+view
            filter.updated = false;
            this.renderExternalFiltersView();
          }
        }
        // DO NOT FORGET TO ADD THE FILTER AREA TO THE ROOT ELEMENT...
        this.externalFiltersRoot.appendChild(filterArea);
      });

      this.renderLocalFiltersView();

      //Update table
      this.tableData = JSON.parse(JSON.stringify(this.cf.allFiltered()));
      this.$refs.easyDataTable.updatePage(1);
    }
  },
  created() {
    instancecount++;
    // console.log("Events.created()", this.$refs.chartsRoot);
    // console.log("Events.created() instancecount: ", instancecount);
    this.currentActivePersons = new Set();
    this.componentid = "events-" + instancecount;
    this.externalFilters = [];
    this.localFilterState = {};

    EventBus.on('filteredPersons', (publicationspayload) => {
      if (publicationspayload.componentid == this.componentid) {
        console.log("ignore person emitted by ourselves", publicationspayload.componentid, this.componentid);
        return;
      }
      this.pendingFilteredPersonsFromPublication = publicationspayload.persons
      //console.log("Events: empfangene gefilterte Personen: ", publicationspayload.persons);

      const existingFilterForComponentid = this.externalFilters.filter(fil => fil.componentid === publicationspayload.componentid)[0];
      if (existingFilterForComponentid) {
        console.log("externalFilters: updating existing external filter for componentid ", publicationspayload.componentid, ": ", publicationspayload);
        existingFilterForComponentid.persons = publicationspayload.persons;
        existingFilterForComponentid.updated = true;
      } else {
        //console.log("externalFilters: adding new external filter for componentid ", publicationspayload.componentid, ": ", publicationspayload);
        this.externalFilters.push({ ...publicationspayload });
        this.externalFilters[this.externalFilters.length - 1].active = false;
      }
      //console.log("externalFilters are now: ", this.externalFilters);
      this.renderExternalFiltersView();
    });

  },


  beforeUnmount() {
    console.log("Events.beforeUnmount()");
  },

  unmounted() {
    console.log("Events.unmounted()");
  },

  deactivated() {
    console.log("Events.deactivated()");
    this.hasBeenDeactivated = true;
  },

  activated() {
    console.log("Events.activated(): ", this.chartsRoot, this.externalFiltersRoot);
  },

  mounted() {
    // first of all, try to get a token from the url
    const params = new URLSearchParams(window.location.search)
    const token = params.get("token");

    this.tableRoot = this.$refs.tableRoot;
    this.chartsRoot = this.$refs.chartsRoot;
    this.externalFiltersRoot = this.$refs.externalFiltersRoot;
    this.localFiltersRoot = this.$refs.localFiltersRoot;

    console.log("Events.mounted()", this, this.$refs.chartsRoot);

    const usepublicdata = PUBLICDATA;
    console.log("usepublicdata: ", usepublicdata);

    fetch("https://flfddr.api.datexis.com" + (usepublicdata ? '/api/csv-events-public' : '/api/csv-events?token=' + token))
      .then((response) => response.json())
      .then((data, tableData) => {
        this.tableRoot = this.$refs.tableData;
        this.chartsRoot = this.$refs.chartsRoot;

        console.log("repotypes in data: " + Array.from(data.reduce((acc, curr) => {
          acc.add(curr.repotype);
          return acc;
        }, new Set())));

        // we set data as an instance attribute
        this.data = data;

        console.log("data: ", this.data.length);

        this.initialAuthors = this.data.reduce((acc, curr) => {
          acc.add(curr.protagonistLabel);
          return acc;
        }, new Set());
        //console.log("initialAuthors: ", this.initialAuthors);
        this.currentActivePersons = this.initialAuthors;

        this.logComparedata()

        // Initialize Crossfilter with the fetched data
        this.cf = crossfilter(this.data);

        // Create dimensions and groups for event types - this is reused by the two pie charts
      
        //is not used:
        //const eventTypeDimension = this.cf.dimension((d) => d.type);
        //const eventTypeGroupByeventsCount = eventTypeDimension.group().reduce(this.reduceCountsAdd, this.reduceCountsRemove, this.reduceCountsInitial);


        // Show charts in view when the page is loaded 
        // and charts are open (value 'true' in chartIsOpen map)
        Object.keys(this.chartIsOpen).filter(key => this.chartIsOpen[key]).forEach(key => this.toggleChart(key))

        // Render all charts
        dc.renderAll();
        this.tableData = this.data

        // see: https://stackoverflow.com/questions/22392134/is-there-a-way-to-attach-callback-what-fires-whenever-a-crossfilter-dimension-fi
        dc.chartRegistry.list().forEach(chart => {
          if (this.mycharts.indexOf(chart) != -1) {
            console.log("Events: preparing for filtered listening: owner is Events. Set listener", chart);
            chart.on("filtered", () => {
              //console.log("filter has changed for chart: ", chart);
              //console.log("filter has changed. All filtered are: ", this.cf.allFiltered());
              this.onChartFiltered();
            });
          }
          else {
            console.log("Events: preparing for filtered listening: owner is not Events. Do not set listener", chart);
          }

        });

      });
  },
};
</script>


