<template>
  <div id="app">
    <div class="tabs" key="tabs">
      <div class="tab" :class="{ active: selectedTab === 'PublicationsView' }" @click="selectTab('PublicationsView')">
        Publications
        <div class="btn-add-tab" :class="{ active: selectedTab === 'PublicationsView' }" @click="addView">+</div>
      </div>
      <div class="tab" :class="{ active: selectedTab === 'EventsView' }" @click="selectTab('EventsView')">
        Events
        <div class="btn-add-tab" :class="{ active: selectedTab === 'EventsView' }"  @click="addView">+</div>
      </div>
      <div v-for="tab in tabs" :key="tab.name" class="tab" :class="{ active: selectedTab === tab.name }" @click="selectTab(tab.name)">
        {{ tab.name }}
        <div class="btn-add-tab" :class="{ active: selectedTab === tab.name }" @click="removeView(tab)" >x</div>
      </div>
    </div>
    
    <div class="tab-content" :key="viewKey">
      <keep-alive>
        <component :is="getComponent(selectedTab)"></component>
      </keep-alive>

    </div>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { ref, defineComponent, createApp } from 'vue';
import EventsView from './components/EventsView.vue';
import PublicationsView from './components/PublicationsView.vue';
import { EventBus } from './lib/EventBus';

export default {
  components: {
    EventsView,
    PublicationsView
  },
  data() {
    return {
      viewKey: 0,
      tabs: [],
      selectedTab: 'PublicationsView', // default selected tab
    };
  },
  methods: {
    addView() {
      console.log("Tab is selected:", this.selectedTab);
      var tabName = this.selectedTab.startsWith('Publications') ? 'Publications-'+(++this.additonalViewsCounters.publications) : 'Events-'+(++this.additonalViewsCounters.events);
      this.tabs.push({ name: tabName, componentid: tabName.toLowerCase() });
      this.selectTab(tabName);
    },
    removeView(tab) {
      const index = this.tabs.findIndex(t => t.name === tab.name);
      if (index !== -1) {
        this.tabs.splice(index, 1);
        if (this.selectedTab === tab.name) {
          // If the removed tab is the currently selected tab, select the first tab
          this.selectTab(this.tabs.length > 0 ? this.tabs[0].name : 'PublicationsView');
        }
        // find out the component id for the tab
        // TODO: we currently manage the component ids twice: here AND in the respective component implementations
        //  but we have not found a way so far to access the component instance (the Proxy object created by Vue.js) given
        //  the component implementation (the values of the components map)
        const componentid = tab.componentid;
        console.log("removeView(): got componentid: ", componentid, this.componentsMap, tab);
        EventBus.emit('externalFilterTabRemoved', {
          componentid
        });
      }
    },
    selectTab(tabName) {
      this.selectedTab = tabName;
    },
    getComponent(tabName) {
      if (tabName.startsWith("Publications-") && !this.componentsMap[tabName]) {
        // in order to create a new instance of a component, we only need to apply the spread-operator {... }...
        this.componentsMap[tabName] = {...PublicationsView};
      }
      else if (tabName.startsWith("Events-") && !this.componentsMap[tabName]) {
        this.componentsMap[tabName] = {...EventsView};
      }
      return this.componentsMap[tabName] || null; // Return the component or null if not found
    }
  },
  created() {
    // Map the tab names to component names
    this.componentsMap = {
      'PublicationsView': 'PublicationsView',
      'EventsView': 'EventsView'
    };
    this.additonalViewsCounters = {
      publications: 1,
      events: 1
    }

    this.eventslog = [];

    EventBus.on('filteredPersons',eventpayload => {
      this.eventslog.push({componentid: eventpayload.componentid,filterState: Object.entries(eventpayload.filterState)
        .reduce((acc,[key,value]) => {
          acc[key] = value instanceof Set ? Array.from(value) : {...value};
          return acc;
        },{})});
      console.log("eventslog is: ", this.eventslog);
    });
  },
  mounted() {
    console.log("App.mounted(): ", this, this.selectedTab, this.selectTab);
    this.selectTab("EventsView");
  },
  setup() {
    // console.log("App.setup(): ", this);
    // const selectedTab = ref('PublicationsView');
    // const selectTab = (tabName) => {
    //   selectedTab.value = tabName;
    // };
    //
    // return {
    //   selectedTab,
    //   selectTab,
    // };
  }
};
</script>
