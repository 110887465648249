
function createTopNOnRenderletListener(groupAsBaseOfTopN,topN,topNCalculationFunction,chartRootElement,width,widthOffset = 150) {
    console.log("width and widthOffset(): ", width, widthOffset);
    const barDistance = 10;
    const offsetFromAxis = 10;
    // calculate the width of the bars given the number of positions
    const barWidth = ((width - (widthOffset + offsetFromAxis)) - ((topN - 1) * barDistance)) / topN;
    // this function calculates the x coordinate for some given topN position
    const xposcalc = (pos) => (barWidth * (pos)) + offsetFromAxis + (barDistance * (pos == 0 ? 0 : pos));

    return (/*chart, filter*/) => {
        console.log("rendered. Calculating topN and updating the view");
        // 1) recalculate the topN values from the group used by the chart
        // 1.1.) obtain the values from the group - note that the spread-operator
        // needs to be used as sorting the original values results in trouble
        const allFromGroup = [...groupAsBaseOfTopN.all()];
        console.log("allFromGroup: ", allFromGroup)
        // let count = 0;
        // 1.2) sort sthe values using the value accessor we want to use (here publications count)
        allFromGroup.sort((l, r) => {
            const lvalue = topNCalculationFunction(l);
            const rvalue = topNCalculationFunction(r);
            // if (++count < 100) {
            //     console.log("l,r for ", l, r, lvalue, rvalue);
            // }
            return rvalue - lvalue
        })
        // 1.3) create the topN array
        const topNFromGroup = [...allFromGroup].splice(0, topN).filter(d => topNCalculationFunction(d) > 0).map(d => d.key);
        console.log("topNFromGroup: ", topNFromGroup);

        // 2) manipulate the bars: hide all bars that do not display topN authors and move the topN
        // authors to the correct position
        // 2.1) access the bars in the current chart
        let bartitles = chartRootElement.querySelectorAll("rect.bar title");
        // console.log("got rects: ", bartitles);
        // 2.2) iterate over the title elements
        bartitles.forEach((title) => {
            // 2.2.1) determine whether the title data is contained in the topN array (the __data__ attribute of the
            // dom/svg element contains the name of the respective authors, i.e. we do not need to look into
            // the title's text value that also displays the actual number of publications for the given author
            let pos = -1;
            if ((pos = topNFromGroup.indexOf(title.__data__.x)) != -1) {
                // 2.2.2) in case the given author is a topN author, we set width and x position
                title.parentNode.setAttribute("width", barWidth);
                title.parentNode.setAttribute("x", xposcalc(pos));
            } else {
                // 2.2.3) otherwise, we set the width of the element to 0
                title.parentNode.setAttribute("width", 0);
            }
        });

        // 2) manipulate the tics on the x axis: hide all tics that do not display topN authors and move
        // the topN tics to the correct position
        // 2.1) access the tics in the current chart
        let tics = chartRootElement.querySelectorAll(".axis.x g.tick");
        // console.log("found tics: ", tics);
        // 2.2) iterate over the tics
        tics.forEach((t) => {
            // 2.2.1) determine whether the current tic corresponds to a topN author - also here
            // we use the __data__ attribute of the dom/svg element
            let pos = -1;
            if ((pos = topNFromGroup.indexOf(t.__data__)) != -1) {
                // 2.2.2) if we have a topN author, set the x position using the translate() function set
                // as a string value on the transform attribute
                t.setAttribute("transform", "translate(" + (xposcalc(pos) + barWidth / 2) + ",0)");
                // we also need to set the opacity to visible as this is not updated when the chart is redrawn
                t.setAttribute("opacity", 1);
            } else {
                // 2.2.3) if we do not have a topN author, hide the tic
                t.setAttribute("opacity", 0);
            }
        });
    };
}

/* utitlity functions for mapping strings to colors */
function stringToColour(str) {
    if (str && str.trim().length == 1) {
        str += (str + str + "-" + str.charCodeAt(0) + str.charCodeAt(0));
    }

    var hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var colour = '#';
    for (let i = 0; i < 3; i++) {
        var value = (hash >> (i * 8)) & 0xFF;
        colour += ('00' + value.toString(16)).substr(-2);
    }
    return colour;
}

function hexToRgb(hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
        return r + r + g + g + b + b;
    });

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}

// see https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
function stringToRgbColorExpr(str, alpha = 1.0) {
    const rgb = this.hexToRgb(this.stringToColour(str));
    return "rgba(" + rgb.r + "," + rgb.g + "," + rgb.b + "," + alpha + ")";
}

export {
    stringToColour,
    hexToRgb,
    stringToRgbColorExpr,
    createTopNOnRenderletListener
}
